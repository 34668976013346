import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push;
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  console.log("router push");
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/register_bind",
    name: "RegisterBind",
    component: () => import("../views/RegisterBind.vue"),
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "/firstPage",
        component: () => import("../views/FirstPage.vue"),
      },
      {
        path: "/category_graph",
        component: () => import("../views/CategoryGraph.vue"),
      },
      {
        path: "/index",
        component: () => import("../views/Index.vue"),
      },
      {
        path: "/industry_structure",
        component: () => import("../views/IndustryStructure.vue"),
      },
      {
        path: "/industry_talent",
        component: () => import("../views/IndustryTalent.vue"),
      },
      {
        path: "/industry_person",
        component: () => import("../views/IndustryPerson.vue"),
      },
      {
        path: "/rival_patent",
        component: () => import("../views/RivalPatent.vue"),
      },
      {
        path: "/tech_route",
        component: () => import("../views/TechRoute.vue"),
      },
      // {
      //   path: "/company_news",
      //   component: () => import('../views/CompanyNews.vue'),
      // },
      {
        path: "/personal",
        component: () => import("../views/Personal.vue"),
      },
      {
        path: "/industry_news",
        component: () => import("../views/IndustryNews.vue"),
      },
      {
        path: "/industry_risk",
        component: () => import("../views/IndustryRisk.vue"),
      },
      {
        path: "/patent_news",
        component: () => import("../views/PatentNews.vue"),
      },
      {
        path: "/company_graph",
        component: () => import("../views/CompanyGraph.vue"),
      },
      {
        path: "/industry_graph",
        component: () => import("../views/IndustryGraph.vue"),
      },

      {
        path: "/industry_business",
        component: () => import("../views/IndustryBusiness.vue"),
      },
      {
        path: "/company_search",
        component: () => import("../views/CompanySearch.vue"),
      },
      {
        path: "/company_info",
        component: () => import("../views/CompanyInfo.vue"),
      },
    ],
  },
  {
    path: "/login_with_token",
    component: () => import("../views/LoginWithToken.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
