//JS操作cookies方法!

export default {
  //写cookies
  setCookie(name, value) {
    var Days = 0.5;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    // exp.setTime(exp.getTime() +  60 * 1000);
    // document.cookie =
    //   name + "=" + escape(value) + ";expires=" + exp.toGMTString() + ";SameSite=None;Secure;";
    document.cookie =
      name + "=" + escape(value) + ";expires=" + exp.toGMTString() + ";";
  },
  setCookieSomeDay(name, value, days) {
    // var Days = 0.5;
    var exp = new Date();
    exp.setTime(exp.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie =
      name + "=" + escape(value) + ";expires=" + exp.toGMTString();
  },
  //读取cookies
  getCookie(name) {
    var arr,
      reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
    else return null;
  },
  //删除cookies
  delCookie(name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = this.getCookie(name);
    if (cval != null)
      document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
  },
};
