<template>
  <div id="app" class="app">
    <router-view />
  </div>
</template>
<script>
import cookie from "./utils/common.js";
import { EventBus } from "./utils/eventBus.js";

export default {
  beforeDestroy() {
    // 关闭窗口清除cookie
    console.log('关闭vue窗口！！！！')
    sessionStorage.clear();
    cookie.delCookie("server");
    cookie.delCookie("serverValue");
    cookie.delCookie("account");
    cookie.delCookie("permissions");
    cookie.delCookie("company_id");
    cookie.delCookie("role");
  },
};
</script>

<style lang="scss">
html {
  background-color: #f6f7f7;
}
* {
  margin: 0;
  padding: 0;
}
// .app {
//   overflow: hidden;
// }
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  /* background-color: #f5f5f5; */
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 20px;
  -webkit-box-shadow: inset 0 0 6px #c5c3c3;
  background-color: #b6b3b3;
}
.el-tooltip__popper {
  z-index: 200 !important;
}
.el-icon-my-link {
  background-image: url('./assets/images/link.png') center no-repeat;
  background-size: 100% 100%;
  font-size: 16px;
  width: 16px;
  height: 16px;
}
.newsDetail {
    .btnBox {
      display: flex;
      margin-top: 30px;
      width: 100%;
      justify-content: center;
    }
    &-content {
      width: 100%;
      // margin-left: 0%;
      // margin-top: 10px;
      margin-bottom: 10px;
      padding-bottom: 20px;
      min-height: 40vh;
      background: #fff;
      -webkit-box-shadow: 0 1px 10px 0 hsla(0, 0%, 63.9%, 0.5);
      box-shadow: 0 1px 10px 0 hsla(0, 0%, 63.9%, 0.5);
      border-radius: 7px;
      &-text {
        margin-left: 60px;
        margin-right: 10px;
        margin-top: 40px;
        max-height: 50vh;
        overflow-y: scroll;
        padding-right: 50px;
        .basicTitle {
          font-size: 16px;
          font-weight: bold;
          margin-top: 10px;
          margin-bottom: 10px;
        }
        .basicInfo {
          margin-top: 5px;
          margin-left: 10px;
        }
      }
      &-header {
        background: #34465a;
        border-radius: 7px 7px 0 0;
        width: 100%;
        // padding: 0 60px;
        min-height: 70px;
        color: white;
        .newsDetailTitle {
          margin-left: 60px;
          padding-top: 10px;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 1px;
        }
        .des {
          margin-left: 60px;
          margin-top: 5px;
        }
        span {
          display: inline-block;
          margin-top: 8px;
          margin-right: 12px;
          font-size: 12px;
          color: #fff;
        }
      }
    }
  }
  #newsTitle, #linkName, #treeName {
    display: none !important;
  }
// .el-icon-my-link::before {
//   content: "\e611";
//   font-size: 16px;
//   visibility: hidden;
// }
</style>
