import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import cookie from "@/utils/common.js"
import './assets/icon/iconfont.css'
import {
  Pagination,
  Loading,
  Button,
  Dialog,
  Form,
  FormItem,
  Input,
  Message,
  MessageBox,
  Tabs,
  TabPane,
  Radio,
  RadioButton,
  RadioGroup,
  Select,
  Option,
  Popover,
  Cascader,
  Checkbox,
  CheckboxGroup,
  Collapse,
  CollapseItem,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DatePicker,
  Table,
  TableColumn,
  Tag,
  Autocomplete,
  Progress,
  Switch,
  Tooltip,
  Badge,
  Tree,
  CheckboxButton,
  MenuItemGroup,
  MenuItem,
  Menu,
  Submenu,
  Card,
  Rate,
  Upload,
  Notification
  
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'; // 默认主题

import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';

import VueContextMenu from "vue-contextmenu"
import { Step, Steps } from 'vant';
import 'vant/lib/index.css';
import 'vant/lib/icon/local.css';

Vue.use(ViewUI)
// element
Vue.use(Pagination)
Vue.use(Button)
Vue.use(Dialog)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Radio)
Vue.use(RadioButton)
Vue.use(RadioGroup)
Vue.use(Select)
Vue.use(Option)
Vue.use(Popover)
Vue.use(Cascader)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(DatePicker)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Tag)
Vue.use(Autocomplete)
Vue.use(Progress)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Badge)
Vue.use(Tree)
Vue.use(CheckboxButton)
Vue.use(Menu)
Vue.use(MenuItemGroup)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Card)
Vue.use(Rate)
Vue.use(Upload)
Vue.component(Notification)
Vue.use(VueContextMenu)
Vue.use(Loading.directive)
Vue.prototype.$loading = Loading.service;
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$notify = Notification;
// vant
Vue.use(Step);
Vue.use(Steps);

Vue.config.productionTip = false
//开发者模式
Vue.config.devtools = true

// 全局变量，当前版本号
Vue.prototype.version = "TechRadar_beta_V1.10.8_20220819"

//文本裁剪
Vue.filter('textCutPure', (text, num) => {
    if (text.length > num) {
        return text.substring(0,num)
    } else {
        return text
    }
})

// 文本裁剪(text为文本内容,num为保留字数)后面加上...
Vue.filter('textCut', (text, num) => {
    if (text.length > num) {
        return text.substring(0,num) + "..."
    } else {
        return text
    }
})


Vue.filter('textChange', (text, num) => {
    if (text) {
        if (text.length > num) {
        return text.replace(/<br>/g,'').substring(0,num) + "..."
        } else {
            return text.replace(/<br>/g,'')
        }
    } else {
        return text
    }
    
})

Vue.filter('textChangeSub',(text) => {
    return text.replace(/<br>/g,'').substring(0,num)
})

// 1为是，0为否
Vue.filter('isOrNot', text => {
    if (text == 1) {
        return "是"
    } else {
        return "否"
    }
})

// 新闻源过滤下划线之后的内容
Vue.filter('webSourceFilter', text => {
    if (text.indexOf("_")!=-1) {
        return text.split("_")[0]
    } else {
        return text
    }
    // return text.indexOf("_")!=-1?text.split("_")[0]:text
})

// 公司介绍的产品信息数组转为字符串
Vue.filter('descTran', text => {
    // console.log("text", text)
    return text.replace(/(\[)|(")|(\])/g, "")
})

// 格式化日期时间
Vue.filter('formatDate', datetime => {
    // console.log("text", text)
    return datetime.replace("T", " ").replace("Z", "")
})

// // 新闻标题关键词标红
// Vue.filter('highlight', (text, keyword) => {
//     let text_arr = text.split(keyword)
//     text = text_arr[0] + `<span style="color:red">${keyword}</span>` + text_arr[1]
//     return text
// })

// 事件总线
Vue.prototype.$EventBus = new Vue()

//使用钩子函数对路由进行权限跳转
router.beforeEach((to, from, next) => {
//   const role = sessionStorage.getItem("role")
const role = cookie.getCookie("role")
  if (!role && to.path !== '/' && to.path != '/login_with_token' && to.path != '/register_bind') {
      next('/');
      Message({
          type: 'warning',
          message: '请您先登录！'
      })
  } else if (to.meta.permission) {
      // 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已
      role === 'admin' ? next() : next('/');
  } else {
      // 简单的判断IE10及以下不进入富文本编辑器，该组件不兼容
      if (navigator.userAgent.indexOf('MSIE') > -1 && to.path === '/editor') {
          Vue.prototype.$alert('vue-quill-editor组件不兼容IE10及以下浏览器，请使用更高版本的浏览器查看', '浏览器不兼容通知', {
              confirmButtonText: '确定'
          });
      } else {
          next();
      }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
