import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    index_industry: "",
    companyNewsList:[],
    companyShortName:''
    
  },
  mutations: {
    changeIndexIndustry(state, newVal) {
      state.index_industry = newVal
    }
  },
  getters: {
    index_industry(state) {
      return state.index_industry
    }
  },
  actions: {
  },
  modules: {
  }
})
